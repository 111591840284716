import { Link } from "react-router-dom";
import "./Orb.scss";

export default function Orb() {
	return (
		// <Link className="Orb" to="/board">
		<div className="Orb">
			<div className="wrapper">
				<div className="loading"></div>
			</div>
		</div>
		// </Link>
	)
}